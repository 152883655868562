import { 
    Stack,
    Paper,
    Link,
    Typography
} from "@mui/material";


function Footer(props) {
        
    return (
        <Stack 
        	direction="row" 
        	spacing={15} 
        	sx={{ ml: 5, mt: 3 }}
        	>
        
        <Typography variant="body2">Copyright aidatasustainability.com (2023)</Typography>
        
        </Stack>
    )
}

export default Footer;
