import { useState, useEffect, useRef } from 'react'
import { LinearProgress,
	Grid,
	Box,
	Stack,
	SwipeableDrawer,
	Paper,
  Button,
  Menu,
  MenuItem,
  Fab
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DownloadIcon from '@mui/icons-material/Download';

import { get } from '../components/Functions'
import { QuickDateRangePicker ,
	CustomDateRangePicker
} from "../components/DateRangePicker"
import LineChart from "../components/Chart/Line"
import ToneDistribution from "../components/Chart/ToneDistribution"
import ArticleTone from "../components/Chart/ArticleTone"
import Radar from "../components/Chart/Radar"
import RelatedCompanies from "../components/Chart/RelatedCompanies"

import Cookies from "js-cookie";

import { CSVLink } from "react-csv";


export default function Dashboard({market, item, ...props}) {
  const csvLink = useRef()
    
	const oneHourToMilliSeconds = 360000;
	const [anchorEl, setAnchorEl] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	
	const [error1, setError1] = useState(null)
	const [isLoaded1, setIsLoaded1] = useState(false)
	
	const [error2, setError2] = useState(null)
	const [isLoaded2, setIsLoaded2] = useState(false)
	
	const [error3, setError3] = useState(null)
	const [isLoaded3, setIsLoaded3] = useState(false)
	
	const [scoreOptions, setScoreOptions] = useState(["ESG Score","SDG Score"]);				
	const [score, setScore] = useState(scoreOptions[0]);
	
	const [interval, setInterval] = useState("1D");
	const [start, setStart] = useState(null);
	const [finish, setFinish] = useState(null); 
	
	const [timeSeries, setTimeSeries] = useState({});
	const [news, setNews] = useState(null);
	const [count, setCount] = useState(null);
	
	const [csvData, setCsvData] = useState([])
	
	const fetchData = (i,s,f,r) => {
		setIsLoaded1(false)
		setIsLoaded2(false)
		setIsLoaded3(false)
		
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
		get("/score/api/v2/time-series/"
			+ market
			+ "?interval=" + i
			+ "&start=" + s.toISOString()
			+ "&finish=" + f.toISOString()
			+ "&ticker=" + item["Issuer Ticker"],
			token
		).then(res => {
			if (!res.isError) {
				setTimeSeries((prevState) => {
						prevState[item["Issuer Ticker"]] = res.data
						return prevState
					})
				setIsLoaded1(true)
				setScoreOptions(Object.keys(res.data))
				setInterval(i)
				setStart(s)
				setFinish(f)
				
    		prepareData(res.data)
			} else {
				setError1(res.error)
			}
		})
		
		get("/news/api/v1/news/"
			+ market
			+ "?interval=" + i
			+ "&start=" + s.toISOString()
			+ "&finish=" + f.toISOString()
			+ "&ticker=" + item["Issuer Ticker"],
			token
		).then(res => {
			if (!res.isError) {
				setNews(res.data)
				setIsLoaded2(true)
			} else {
				setError2(res.error)
			}
		})
		
		get("/news/api/v1/related-companies/"
			+ market
			+ "?interval=" + i
			+ "&start=" + s.toISOString()
			+ "&finish=" + f.toISOString()
			+ "&ticker=" + item["Issuer Ticker"],
			token
		).then(res => {
			if (!res.isError) {
				setCount(res.data)
				setIsLoaded3(true)
			} else {
				setError3(res.error)
			}
		})
		
		
	}
	
	const prepareData = (items) => {
		let data = []
		//console.log(items)
		for (var d in items[score]) {
			var row = { "DATE": d }
			for (var s in scoreOptions) {
				if (items[scoreOptions[s]][d]===undefined) {
					row[scoreOptions[s]] = 0
				} else {
					row[scoreOptions[s]] = items[scoreOptions[s]][d]
				}
				
			}
			data.push(row)
		}
		setCsvData(data)
	}
	
  const handleDownLoad = () => {
    csvLink.current.link.click()
    
  }

  
  useEffect(() => {
    const now = new Date()
    const finish = new Date(now.getTime() - now.getTimezoneOffset() * oneHourToMilliSeconds)
    var start = new Date(now.getTime() - now.getTimezoneOffset() * oneHourToMilliSeconds)
    start.setMonth(start.getMonth() - 3)
  	fetchData("1D",start,finish,"3M")
  }, [market, item])
  
  return (
  	
		<Stack
			direction={{ lg: "row", md: "column", sm: "column", xs: "column" }}
			spacing={1}
			>
		<Box
			sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" }, minWidth: 200 }}
			>
  		<QuickDateRangePicker 
  			onChange={fetchData}
  			/>
  		<CustomDateRangePicker 
  			onChange={fetchData}
  			/>
			<Button startIcon={<DownloadIcon />}
				variant="outlined"
				sx={{ mt: 1}}
				onClick={() => handleDownLoad()}
				>
				Download CSV file
			</Button>
		</Box>
		<Box
			sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none" }, 
				position: "fixed",
				bottom: 20,
				right: 20
			}}
			>
			<Fab onClick={() => setIsOpen(!isOpen)}
				color="primary"
				>
				<CalendarMonthIcon />
			</Fab>		
			
			<Fab onClick={() => handleDownLoad(!isOpen)}
				color="primary"
				sx={{ ml: 1}}
				>
				<DownloadIcon />
			</Fab>	
		</Box>
		<Grid container
  		spacing={1}
  		>
		
  		<Grid item
  			xs={12} lg={6}
  			>
  			<Paper 
  				variant="card-header"
	    		sx={{ lineHeight: 2.4, pl: 1 }}
  				>
  				<Button
						variant="text"
						onClick={(e) => setAnchorEl(e.currentTarget)}
						endIcon={<ArrowDropDownIcon />}
						sx={{
							fontWeight : 600,
							color: "inherit",
							minWidth: 100,
							padding: "0 0 0 0",
						}}
						>
						{score}
					</Button>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={() => setAnchorEl(null)}
						>
						{scoreOptions.map(s => (
							<MenuItem key={s} value={s}
								onClick={() => setScore(s)}
								>{s}</MenuItem>
						))}
					</Menu>
  			</Paper>
  			{error1
  				? <p>{error1.message}</p>
  				: !isLoaded1
  					? <LinearProgress />
  					: <LineChart items={timeSeries} 
  							index={score}
  							interval={interval}
  							/>
  			}
  			
  		</Grid>
  		<Grid item
  			xs={12} lg={6}
  			>
  			<Paper 
  				variant="card-header"
	    		sx={{ lineHeight: 2.4, pl: 1 }}
  				>
  				TONE DISTRIBUTION
  			</Paper>
  			{error2
  				? <p>{error2.message}</p>
  				: !isLoaded2
  					? <LinearProgress />
  					: <ToneDistribution items={news} />
  			}
  			
  		</Grid>  		
  		
  		<Grid item
  			xs={12} lg={6}
  			>
  			<Paper 
  				variant="card-header"
	    		sx={{ lineHeight: 2.4, pl: 1 }}
  				>
  				RELATED COMPANIES
  			</Paper>
  			{error3
  				? <p>{error3.message}</p>
  				: !isLoaded3
  					? <LinearProgress />
  					: <RelatedCompanies items={count} market={market}/>
  			}
  			
  		</Grid>
		
  	</Grid>
  	
			
		<SwipeableDrawer
			open={isOpen}
			anchor="left"
			onClose={(e) => setIsOpen(false)}
			onOpen={(e) => setIsOpen(true)}
			>
			<Box 
				sx={{ minWidth: 250 }}
				align="center"
				>
			<QuickDateRangePicker 
				onChange={fetchData}
				/>
			<CustomDateRangePicker 
				onChange={fetchData}
				/>
			</Box>
		</SwipeableDrawer>
		
		<CSVLink
        headers={['DATE'].concat(scoreOptions)} 
        data={csvData}
        filename={'Historical Data ' + new Date().toISOString().split('.')[0] + '.csv'}
        className='hidden'
        ref={csvLink}
        target='_blank'
        />
  	</Stack>
  )
}
