import { useState } from 'react'
import { Box,
	Stack, 
	Button,
	Typography,
	Table,
	TableRow,
	TableCell,
	TextField,
	Divider
} from "@mui/material"
import Cookies from "js-cookie";
import PersonIcon from '@mui/icons-material/Person';
import { put } from "../components/Functions"

function AccountSettingPage (props) {
  
  const user = JSON.parse(localStorage.getItem('user'))
  const [editProfile, setEditProfile] = useState(false)
  
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  
  
  const handleSaveProfile = () => {
		let token = Cookies.get("AIDATAME_AUTH_TOKEN")
  	put("/user/api/v3/user/auth",
  		{ family_name: lastName, given_name: firstName},
  		token
  	).then((res) => {
  		if (!res.isError) {
  			localStorage.setItem("user", JSON.stringify(res.data))
  			setFirstName(res.data.given_name)
  			setLastName(res.data.family_name)
  			setEditProfile(false)
  		} else {
  			alert(res.error)
  		}
  	})
  }
  
  
  if (user) {    
    return (
    	
      <Box
      	sx={{ mt: 5 , mb: 5 }}
      	>
      <Stack direction="column"
      	spacing={5}
      	>
     	<Box>
     		<Typography variant="h5"
     			sx={{ mb : 1}}
     			>
     			<PersonIcon sx={{ fontSize: 35 }}/> &nbsp;
     			Profile &nbsp;&nbsp;
     			<Button variant="outlined"
     				onClick={() => setEditProfile(!editProfile)}
     				>
     				Edit profile
   				</Button>
     		</Typography>
     		
     		<Divider />
     		<Table
     			sx={{ maxWidth: 500, mb: editProfile ? 3 : 0  }}
     			>
     		<TableRow>
     			<TableCell>
     				<Typography variant="subtitle1">Email</Typography>
   				</TableCell>
   				<TableCell>{user.email}</TableCell>
     		</TableRow>
     		<TableRow>
     			<TableCell>
     				<Typography variant="subtitle1">First Name</Typography>
   				</TableCell>
   				<TableCell>
   					{editProfile 
   						? <TextField variant="standard"
   								defaultValue={user.given_name}
   								onChange={(e) => setFirstName(e.target.value)}
   								value={firstName}
   								/>
   						: user.given_name
 						}
 					</TableCell>
     		</TableRow>
     		<TableRow>
     			<TableCell>
     				<Typography variant="subtitle1">Last Name</Typography>
   				</TableCell>
   				<TableCell>
   					{editProfile 
   						? <TextField variant="standard"
   								defaultValue={user.family_name}
   								onChange={(e) => setLastName(e.target.value)}
   								value={lastName}
   								/>
   						: user.family_name
 						}
   				</TableCell>
     		</TableRow>
   			</Table>
   			
   			{editProfile
   				? <Button variant="contained"
			 				onClick={() => handleSaveProfile()}
			 				>
			 				Save changes
			 			</Button>
		 			: ""
	 			}
     	</Box>
     	
     	
     	
			</Stack>
      </Box>
    );
  } else {
    return (
      <Typography align="center">
      	Login or Sign-up to our website.
      </Typography>
    )
  }

}

export default AccountSettingPage;


