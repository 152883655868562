import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    TablePagination,
    Typography,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

function Score({items, label, index, onEmptyListShow, ...props}) {
	let navigate = useNavigate();
	
	const [orderBy, setOrderBy] = useState(null);
	const [asc, setAsc] = useState(true);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	
	const handleChangeRowsPerPage = (e) => {
		setRowsPerPage(parseInt(e.target.value, 10));
  	setPage(0);
	}
	
  const compare = (a, b, field, ascending) => {
    if (ascending) {
      if (a[field] < b[field]) {
        return -1
      } else {
        return 1
      }
    } else {
      if (a[field] < b[field]) {
        return 1
      } else {
        return -1
      }
    }
  }
  
  const handleRowClick = (row) => {
  	navigate("/" + row.market + "/" + row["Issuer Ticker"])
  }
	
	useEffect(() => {}, [items, index, label])
	
	return (
		<div>
		<Table >
		<TableHead>
			<TableRow>
			<TableCell onClick={() => setOrderBy("Issuer Ticker")}>

			<TableSortLabel
				active={orderBy === "Issuer Ticker"}
				direction={asc ? "asc" : "desc"}
				onClick={() => setAsc(!asc)}
				>
				TICKER
			</TableSortLabel>

			</TableCell>
			
			<TableCell onClick={() => setOrderBy(index)}>
			<TableSortLabel
				active={orderBy === index}
				direction={asc ? "asc" : "desc"}
				onClick={() => setAsc(!asc)}
				>
				LAST {label}
			</TableSortLabel>
						   
			</TableCell>
			</TableRow>
		</TableHead>

		<TableBody>
		{items.length>0
			? <>{items
				.sort((a,b) => compare(a, b, orderBy, asc))
				.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
				.map(d => (
					<TableRow hover
						key={d["Issuer Ticker"]} 
						onClick={() => handleRowClick(d)}>
						<TableCell>
						
						<Typography 
							variant="caption"
							sx={{ fontWeight: 600 }}
							>{d.Name.toUpperCase()}
						</Typography>&nbsp;&nbsp;
						<Typography variant="overline">{d._id ? d._id : d["Issuer Ticker"]}</Typography> 
						</TableCell>
						
						<TableCell align="right">
						{d[index]
							? d[index].toFixed(2)
							: "N/A"
						}
						</TableCell>
					</TableRow>
				))}</>
			: onEmptyListShow
		}
		</TableBody>
		</Table>
		
		<TablePagination
			rowsPerPageOptions={[10, 25, 50]}
			component="div"
			count={items.length}
			rowsPerPage={rowsPerPage}
			page={page}
			onPageChange={(e, p) => setPage(p)}
			onRowsPerPageChange={handleChangeRowsPerPage}
			/> 
		</div>
	)
}

export default Score;
