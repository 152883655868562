import { useState } from "react";
import { useNavigate } from "react-router-dom";

import UserProfile from './UserProfile';
import Search from './Search';
import Logo from '../favicon.svg';

import {
	AppBar,
	Box,
	Toolbar,
	IconButton,
	Button,
	Collapse,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Stack
} from "@mui/material"

import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import SearchIcon from '@mui/icons-material/Search';

function CustomNavbar({theme, setTheme, ...props}) {
    
	let navigate = useNavigate();
	let user = JSON.parse(localStorage.getItem("user"));
	
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [searchbarOpen, setSearchbarOpen] = useState(false);
  
  
  return (
	<AppBar position="static"
		sx={{ backgroundColor: "#282c34", flexGrow: 1 }}
		>
		<Toolbar >
		<IconButton onClick={() => navigate("/")}>
			<Logo width={50}/>
		</IconButton>
			
			<Button variant="text"
				startIcon={<Brightness4Icon />}
				onClick={() => setTheme(!theme)}
				sx={{ display: { lg: "flex", md: "none", sm: "none", xs: "none" } }}
				color="inherit"
				>
				LIGHTS {theme ? "OFF" : "ON"}
			</Button>
			<Box sx={{ flexGrow: 1}}>
			</Box>
		
		
		
			<IconButton color="inherit"
				sx={{ display: { md: "none", lg: "none", sm: "block", xs: "block" } }}
				onClick={() => setSearchbarOpen(!searchbarOpen)}
				>
				<SearchIcon />
			</IconButton>	
		
			<Box
				sx={{ display: { sm: "none", xs: "none", md: "flex", lg: "flex" } }}
				>
			<Search 
				onHitClick={(item) => navigate("/" + item.market + "/" + item.issuer_ticker)}
				
				/>
			</Box>
			
			<IconButton 
				onClick={() => setNavbarOpen(!navbarOpen)}
				color="inherit"
				sx={{ display: { md: "block", lg: "none", sm: "block", xs: "block" } }}
				>
				<MenuIcon />
			
			</IconButton>
			
		{user
			? <UserProfile user={user}
				sx={{ display: { sm: "none", xs: "none", md: "none", lg: "flex" }, ml: 1 }}
				/>
			: <Stack spacing={1} 
				direction="row"
				sx={{ display: { sm: "none", xs: "none", md: "none", lg: "flex" }, ml: 1 }}
				>
				<Button variant="contained"
					onClick={() => navigate("/login")}
					>LOGIN</Button>
					
				<Button variant="outlined"
					onClick={() => navigate("/sign-up")}
					>SIGN UP</Button>
			</Stack>
		}
		</Toolbar>
		<Collapse orientation="vertical"
			in={searchbarOpen}
			sx={{ display: { sm: "block", xs: "block", md: "block", lg: "none" }, ml: 5, mb: 1 }}
			>
			<Search 
				onHitClick={(item) => navigate("/" + item.market + "/" + item.issuer_ticker)}
				
				/>
		</Collapse>
		
		<Collapse orientation="vertical"
			in={navbarOpen}
			sx={{ display: { sm: "block", xs: "block", md: "block", lg: "none" } }}
			>
			
			<List>
			{user
				? <UserProfile user={user}
						sx={{ ml: 2 }}
						/>
				: <Stack spacing={0} 
						>
					<ListItem disablePadding
						onClick={() => navigate("/login")}
						>
						<ListItemButton>
							<ListItemText primary="LOGIN"/>
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding
						onClick={() => navigate("/sign-up")}
						>
						<ListItemButton>
							<ListItemText primary="SIGN UP"/>
						</ListItemButton>
					</ListItem>
				</Stack>
			}
			
			<ListItem>
			<Button variant="text"
				startIcon={<Brightness4Icon />}
				onClick={() => setTheme(!theme)}
				>
				LIGHTS {theme ? "OFF" : "ON"}
			</Button>
			</ListItem>
			</List>
		</Collapse>
	</AppBar>
	
  );

}

export default CustomNavbar
