import { createTheme } from "@mui/material/styles";

const darkMode = createTheme({
	palette: {
		mode: "dark",
		
	},
	typography: {
		fontSize: 12
	},
	components: {
		MuiTable: {
			defaultProps: {
				size: "small"
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: { minHeight: 30 }
			}
		},
		MuiButton: {
			defaultProps: {
				size: "small",
				disableElevation: true
			}, 
			styleOverrides: {
				root: {
					textTransform : "none"
				}
			}
		},
		MuiPaper: {
			variants: [
				{
					props: { variant: "card-header"},
					style: {
						backgroundColor: "#292f38",
						paddingLeft: 2,
						borderRadius: 0,
						fontWeight: 600
					}
				},
				{
					props: { variant: "modal"},
					style: {
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: 'background.paper',
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
						overflow: "auto",
						maxHeight: "80vh"
					}
				},
			]
		}
	}
})

const lightMode = createTheme({
	palette: {
		mode: "light",
		
	},
	typography: {
		fontSize: 12
	},
	components: {
	
		MuiTable: {
			defaultProps: {
				size: "small"
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: { minHeight: 30 }
			}
		},
		MuiButton: {
			defaultProps: {
				size: "small",
				disableElevation: true
			}, 
			styleOverrides: {
				root: {
					textTransform : "none"
				}
			}
		},
		MuiPaper: {
			variants: [
				{
					props: { variant: "card-header" },
					style: {
						backgroundColor: "rgba(0, 140, 255, 0.2)",
						paddingLeft: 1,
						borderRadius: 0,
						fontWeight: 600,
					}
				},
				{
					props: { variant: "modal"},
					style: {
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: 'background.paper',
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
						overflow: "auto",
						maxHeight: "80vh"
					}
				},
			]
		},
		MuiTypography: {
			variants: [
				{
					props: { variant: "muted" },
					style: {
						color: "#6c757d",
						fontWeight: 500,
						display: "block"
					}
				}
			]
		}
	}
})

export { darkMode, lightMode };
