import { useState } from 'react'
import {
	Menu,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Avatar,
  Box,
  Divider,
  Button
} from '@mui/material'
import { useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';

export default function UserProfile({user, ...props}) {
    
  const [anchorEl, setAnchorEl] = useState(null)
    
	let navigate = useNavigate();
	
  const logOut = () => {
      localStorage.removeItem('user')
      localStorage.removeItem('watchlists')
      navigate('/login')
  }
  
  return (
  	<Box {...props}>
  		
	{user.picture
		? <Avatar src={user.picture}  
			width="40" 
			height="40" 
			alt='user'
    		onClick={(e) => setAnchorEl(e.currentTarget)}
			/>
		: user.family_name && user.given_name
			? <Avatar src={user.picture}  
					width="40" 
					height="40" 
					alt='user'
					onClick={(e) => setAnchorEl(e.currentTarget)}
					>
					{user.given_name[0]}{user.family_name[0]}
				</Avatar>
			: <Button
				variant="text"
				color="inherit"
				size="small"
				onClick={(e) => setAnchorEl(e.currentTarget)}
				>
				{user.email}
			</Button>
	}
	<Menu
      	open={Boolean(anchorEl)} 
      	anchorEl={anchorEl}
      	onClose={(e) => setAnchorEl(null)}
		>
      <List dense 
      	>
      	{user.is_admin
			    ? <ListItem
			    		onClick={() => {navigate("/admin"); setAnchorEl(null)}}
			    		>
			    	<ListItemButton>
			    	<ListItemIcon><AdminPanelSettingsIcon /></ListItemIcon>
			    	<ListItemText>Manage users</ListItemText>
			    	</ListItemButton>
			    	</ListItem>
				  : ""
			  }
      	<ListItem>
      	<ListItemButton
      		onClick={() => {navigate("/account"); setAnchorEl(null)}}
      		><ListItemText>Account Setting</ListItemText>
      		</ListItemButton>
    		</ListItem>
    		
      	<Divider />
      	
      	<ListItem>
      	<ListItemButton
      		onClick={() => logOut()}
      		>
      		<ListItemIcon><LogoutIcon /></ListItemIcon>
      		<ListItemText>Log Out</ListItemText>
      		</ListItemButton>
      	</ListItem>
      </List>
      </Menu>
      </Box>
  )
    
};
