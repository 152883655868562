import { Line } from 'react-chartjs-2';
import { useState, useEffect } from 'react'

export default function AreaChart(props) {
    const nbBin = 10
    const [items, setItems] = useState([])
    
    useEffect(() => {
        let tones = []
        if (props.items) {
            for (var i=0; i<props.items.length; i++) {
                tones.push(props.items[i].Tone)
            }
        }
        setItems(tones)
    }, [props.items, nbBin])
    
    
    const sorted_tones = items.sort((a,b) => a-b)
    const min_t = sorted_tones[0] 
    const max_t = sorted_tones[sorted_tones.length-1]
    
    const lbl = []
    const d = []
    var j=0
    for (var i=0; i<nbBin; i++) {
        lbl.push(min_t + (max_t-min_t)/nbBin*i)
        var c=0
        while (sorted_tones[j]<=lbl[i]) {
            ++c
            ++j
        }
        d.push(c/sorted_tones.length*100)
    }
    
//     console.log(lbl)
//     console.log(d)
    const data = {
        labels: lbl,
        datasets: [{
            data: d, 
            fill: true, 
            pointRadius: 0, 
            borderColor: 'rgba(0,104,255,0.8)',
            backgroundColor: 'rgba(0,104,255,0.2)',
            borderWidth: 2,
            lineTension: .3
        }]
    }
    
    const options = {
        maintainAspectRatio: true,
        plugins: {
            legend:{
                display: false
            },
            tooltip: {
                enabled: false,
                
            }
        },
        scales: {
            x: {
                ticks: {
                    maxTicksLimit: 5,
                    maxRotation: 0,
                    minRotation: 0,
                    callback: function(value, index, values) {
                        return this.getLabelForValue(value).toLocaleString()
                    }
                },
                grid: {
                    display: false,
                },
                title: {
                    text: 'Tone',
                    display: true,
                }

            },
            y: {
                grid: {
                    display: false,
                },
                title: {
                    text: 'Frequency',
                    display: true,
                }
            }
        }
        

    }
    
    
    
    return (
        <Line data={data} options={options}/>
    )
}
