import { useState, useRef } from 'react'
import { 
  Tabs,
  Tab,
  Button
} from '@mui/material'

export default function Markets({onItemClick, items, ...props}) {
  const [market, setMarket] = useState(0)
  
  const handleSelectedMarket = (m, i) => {
    setMarket(i)
    onItemClick(m, i)
  }
  
  
  return (
	    
    <Tabs
    	value={market}
    	onChange={(e,v) => setMarket(v)}
		  scrollButtons="auto"
		  indicatorColor={null}
		  sx={{ alignItems: "center" }}
		  variant="scrollable"
    	>

    {items.map((v, i) => (
		   <Button variant={market===i ? "contained" : "outlined"}
		   	key={i}
				onClick={() => handleSelectedMarket(v,i)}
				sx={{ padding: "0px 5px 0px 5px", ml: .5 }}
				>
				{v}
			</Button>
     ))}
    </Tabs>
        
    )
}
