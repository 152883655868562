import { ListItemButton,
	ListItemText,
	MenuItem,
	IconButton,
	ListItem
} from '@mui/material';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

const Hit = ({hit, onClick, onSecondaryAction = (h) => {}, ...props}) => {
	return (
		<ListItem
			secondaryAction={hit.customdata
				? <IconButton color={hit.customdata.in_watchlist ? "primary" : "default"}
						onClick={() => onSecondaryAction(hit)}
						>
						<PlaylistAddCheckIcon />
					</IconButton>
				: ""
			}
			>
			<ListItemButton
				onClick={() => onClick(hit)}
				>
			<ListItemText 
				primary={hit.fullname}
				secondary={
					<>{hit.issuer_ticker}
						&nbsp;&bull;&nbsp;
						{hit.market}
						&nbsp;&bull;&nbsp;
						{hit.location}</>
					}
				/>
			</ListItemButton>
		</ListItem>
	)
};

export default Hit;

