
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const colors = [
    '#6184D8',
    '#50C5B7',
    '#9CEC5B',
    '#F0F465',
    '#5C2751'
]

function convert(date, interval) {
    let x = null
    let d = date.split('T')
    let d1 = d[0].split('-')
    if (interval==='1H') {
        x = d[1].substring(0,5)
    } else if (interval==='1M') {
        x = months[parseInt(d1[1])-1] + ' ' + d1[0]
    } else if (interval==='1Y') {
        x = d1[0]
    } else {
        x = d1[2] + '/' + d1[1];
    }
    return x
}

function colorGenerator(i, alpha=0.8) {
    const rgb = [0,10,255]//[31,142,241]
    const color = 'rgba(' + rgb[0] + ',' + (rgb[1] + 25*(i+1)) + ',' + rgb[2] +', ' + alpha + ')'
    
    return color//'rgba(' + 0 + ',' + (i+1)*30 + ',' + (i+1)*80.1 +', ' + alpha + ')'
}


export { convert, colorGenerator, colors, months }