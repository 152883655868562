import { useState } from 'react'
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import {
	List,
	ListItem,
	ListItemButton,
	ListSubheader,
	Button,
	Modal,
	Box,
	Stack,
	Chip,
	Paper
} from "@mui/material"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


function QuickDateRangePicker({onChange, ...props}) {

	const items = [
		{
		  index: '24H',
		  label: "Past 24 hours",
		  getValue: () => {
		    const today = dayjs();
		    return ["1H", today.subtract(1, "day"), today, "24H"];
		  },
		},
		{
		  index: '7D',
		  label: "Past week",
		  getValue: () => {
		    const today = dayjs();
		    return ["1D", today.subtract(7, 'day'), today, "7D"];
		  },
		},
		{
		  index: '3M',
		  label: "Past 3 months",
		  getValue: () => {
		    const today = dayjs();
		    return ["1D", today.subtract(3, 'month'), today, "3M"];
		  },
		},
		{
		  index: '1Y',
		  label: "Past year",
		  getValue: () => {
		    const today = dayjs();
		    return ["1M", today.subtract(1, 'year'), today, "1Y"];
		  },
		},
		{
		  index: 'All',
		  label: "All",
		  getValue: () => {
		    const today = dayjs();
		    const start = dayjs("2015-01-01");
		    return ["1Y", start, today, "All"];
		  },
		},
	];
	
	const handleOnChange = (e) => {
		
		onChange(e[0], e[1], e[2], e[3])
	}
	
	
  return (
		<List dense
			subheader={
				<ListSubheader>QUICK ACTIONS</ListSubheader>
			}
			{...props}
			>
		{items.map((i) => (
			<ListItem key={i.index}
				onClick={() => handleOnChange(i.getValue())}
				>
				<ListItemButton>{i.label}</ListItemButton>
			</ListItem>
		))}
		</List>
            
  )
}

function DateRangePickerModal ({onChange, onClose, ...props}) {
	const [start, setStart] = useState(null)
	const [finish, setFinish] = useState(null)
	const [interval, setInterval] = useState("1D")
	
	const handleOnChange = (i,s,f) => {
		onChange(i,s,f)
		onClose()
	}
	
	const handleOnClose = () => {
		onClose()
	}
		
  return (
	  <LocalizationProvider dateAdapter={AdapterDayjs}>
		<Stack direction={{
			xs: "column",
			sm: "column",
			md: "row",
			lg: "row",
		}}>
			<Box>
			<h4>From: &nbsp;{start ? start.toISOString().slice(0,10) : ""}</h4>
		  <DateCalendar 
		  	value={start}
		  	views={["day"]}
		  	onChange={(v) => setStart(v)}
		  	/>
		  </Box>
		  
		  <Box>
		  <h4>To: &nbsp;{finish ? finish.toISOString().slice(0,10) : ""}</h4>
		  <DateCalendar 
		  	value={finish}
		  	views={["day"]}
		  	onChange={(v) => setFinish(v)}
		  	/>
		  </Box>
  	</Stack>
	  <Chip label={"1H"}
	  	color={interval==="1H" ? "primary" : "default"}
	  	onClick={() => setInterval("1H")}
	  	/>
	  <Chip label={"1D"}
	  	color={interval==="1D" ? "primary" : "default"}
	  	onClick={() => setInterval("1D")}
	  	/>
  	<Stack spacing={0.5}
  		direction="row"
  		justifyContent="flex-end"
  		>
  	<Button
  		variant="contained"
  		onClick={() => handleOnChange(interval, start, finish)}
  		>OK</Button>
  	<Button
  		variant="outlined"
  		onClick={() => onClose()}
  		>Cancel</Button>
  	</Stack>
	  </LocalizationProvider>
  )
}

function CustomDateRangePicker({onChange, ...props}) {
  const [open, setOpen] = useState(false)
    
  return (
  	<div>
  	<Button startIcon={<CalendarMonthIcon />}
  		onClick={() => setOpen(!open)}
  		variant="contained"
  		size="medium"
			>
  		Custom selection
  	</Button>
  	
  	<Modal open={open}
  		onClose={() => setOpen(false)}
  		>
  		<Paper
  			variant="modal"
  			sx={{ pl: 3, pr: 3, pb: 3 }}
  			>
  			<DateRangePickerModal 
  				onClose={() => setOpen(false)}
  				onChange={onChange}
  				/>
  		</Paper>
  	</Modal>
  	</div>
  )
}

export { QuickDateRangePicker, CustomDateRangePicker }
